<template>
  <div class="cate-name">
    <h3>{{ cateName }}</h3>
    <h1>${{ price }}/年</h1>
  </div>
  <div class="level-info" v-for="info in levelInfo" :key="info">
    <span><i class="iconfont icon-duigouxiao"></i></span>
    <p>{{ info }}</p>
  </div>

</template>

<script>
export default {
  name: "Level",
  props: {
    cateName: {
      type: String,
      required: true
    },
    levelInfo: {
      type: Array,
      required: true
    },
    price: {
      type: String,
      required: true
    }
  },
}
</script>

<style scoped>

.cate-name{
  padding: 20px;
  background: var(--background) url("~@/assets/img/top.png");
  color: #fff;
  margin-bottom: 20px;
}
.cate-name h1{
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  .cate-name h1{
    font-size: 24px;
  }
  .cate-name h3{
     font-size: 18px;
  }
}

</style>