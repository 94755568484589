<template>

  <div v-if="isShow">
    <loading/>
  </div>
<div class="Member-bg">
   <div class="middle common-padding-half">
        <div class="member-info">
          <h1>會員中心</h1>
          <div class="member-info-base">
            <div class="avatar">
               <div class="change">
                 <img src="@/assets/img/pic18.png">
                 <div class="upload-img" >
                   <input type="file" ref="imageInput" @change="handleImageChange"/>
                 </div>
               </div>
                 <img :src="avatar || defaultAvatarUrl" class="personal-avatar">
             </div>
            <div class="info-other">
               <div class="info-other-1">
                 <h3>{{ user_info.name }}</h3>
                 <span>{{ user_info.invitation_code }}</span>
               </div>
              <div class="info-other-2">
                 <div>
                   電郵: {{ user_info.email }}
                   <router-link to="/changeEmail">
                     修改
                   </router-link>
                 </div>
                <div>
                  電話號碼: {{ user_info.area_code }}-{{ user_info.phone }}
                  <span>
                    <a href="#" @click="edit">
                        修改
                    </a>
                  </span>
                </div>

                <div class="share">
                  <span>分享鏈接：</span>
                  <span class="share-link">https://www.charmingspring.com/register/{{user_info.invitation_code}}</span>
                  <span class="copy" @click="copyOrderId1">復製</span>
               </div>
              </div>
              <div class="info-other-3">
                <div class="info-other-btn info-other-btn-2">
                 <router-link to="/changePassword">
                  修改密碼
                 </router-link>
                </div>
                <div class="info-other-btn" @click="logout">
                     退出登錄
                </div>
              </div>
            </div>
          </div>
           <div class="member-moreInfo">
             <Info
                 :memberName="user_info.member_name"
                 :expirationDate="user_info.vip_expire_date"
                 :membershipNumber="user_info.invitation_code"
                 :points="user_info.calculus"
                 :id="user_info.member_id"
                 :memberData="memberLevels"
             />
           </div>
        </div>
   </div>



  <div class="bgc">
    <div class="middle common-padding-half">
      <div class="common-title">
        <div class="common-line"></div>
        <span>
          會員等級介紹
        </span>
        <div class="common-line"></div>
      </div>

      <div class="cate">
        <ul>
          <li v-for="item in memberLevels" :key="item.name">
            <Level
                :cate-name="item.title"
                :price="item.price"
                :level-info="item.description"
            />

            <div class="bank-info cate-bank">
              <BankInfo
                  :titleOne="bankInfo.corporation_title"
                  :companyName="bankInfo.corporation"
                  :titleTwo="bankInfo.fps_title"
                  :fpsId="bankInfo.fps"
                  :titleThree="bankInfo.bank_title"
                  :accountNumber="bankInfo.bank_account"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

<!-- 弹窗 -->
  <div class="dialog-wrapper" v-if="visible">
    <div class="dialog-container">
      <div class="dialog-header">
        <div class="dialog-title">修改電話號碼</div>
        <div class="dialog-close" @click="closeDialog">
          <i class="iconfont icon-guanbi1"></i>
        </div>
      </div>
      <div class="dialog-content">
        <div class="m-form-item">
          <p>電話號碼<span>*</span></p>
          <div class="v-code"  style="width: 100%;">
            <AreaCode v-model:areaCode="areaCode" @update:areaCodeID="handleAreaCodeIDUpdate" />
            <el-input v-model="tel"  class="m-input tel-input" />
          </div>
        </div>
        <div class="m-form-item">
          <p>驗證碼</p>
          <div style=" width: 100%;position: relative">
            <div class="yzm" :class="{ 'disabled': isCountdownActive }" @click="sendCode">
              {{ countdownText }}
            </div>
            <el-input v-model="code"  class="m-input" />
          </div>
        </div>

      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="changePhone">确定</el-button>
      </div>
    </div>
  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

<!--小加载，用于接口反应-->
  <div class="loading-container" v-if="apiShow">
    <div class="loading-container-c">
      <div class="loading-spinner"></div>
    </div>
  </div>

</template>

<script>
import Info from "@/components/Info";
import Level from "@/components/Level";
import BankInfo from "@/components/BankInfo";
import Loading from "@/components/loading";
import AreaCode from "@/components/AreaCode";
import defaultAvatarUrl from '@/assets/img/avator.png'
import {changePhone, getMemberInfo, logout, sendCode, UploadAvatar} from "@/network/main";
export default {
  name: "MemberCentre",
  components:{
    Info,
    Level,
    BankInfo,
    Loading,
    AreaCode
  },
  data() {
    return {
      user_info:'',
      memberLevels: [],
      bankInfo:'',
      isShow:true,
      isPopupVisible:false,
      popupText:'',
      email:'',
      code:'',
      tel:'',
      areaCode:'+852',
      areaCodeID: null,
      countdownTimer: null,
      countdownSeconds: 60,
      isCountdownActive: false,
      visible:false,
      avatar:'',
      defaultAvatarUrl: defaultAvatarUrl,
      apiShow:false,
    }
  },
  computed: {
    countdownText() {
      return this.isCountdownActive ? `${this.countdownSeconds}s` : '發送驗證碼';
    }
  },
  created() {
    this.getMemberInfo()
  },
  methods:{
    //
    getMemberInfo(){
      getMemberInfo().then(res =>{
        this.isShow = false;
        this.user_info = res.data.data.user_info;
        this.memberLevels =res.data.data.member;
        this.bankInfo =res.data.data.bank;
        this.avatar = res.data.data.user_info.photo;
      })
    },
    //退出登录
    logout(){
      logout().then(res =>{
        console.log(res)
        localStorage.removeItem('token');
        this.$router.push('/');
        setTimeout(() => {
          location.reload();
        }, 500)
      })
    },

    //发送验证码
    sendCode() {
      this.startCountdown()
      const formData = new FormData();
      formData.append('email', this.user_info.email);
      sendCode(formData).then((res)=>{
        if(res.data.code===200){
          this.isPopupVisible = true
          this.popupText="驗證碼已發送！"
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }else {
          this.isPopupVisible = true;
          this.popupText = res.data.message;
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }
      }).catch(err=> {
        console.log(err)
      })
    },
    //倒計時
    startCountdown() {
      this.isCountdownActive = true;
      this.countdownTimer = setInterval(() => {
        this.countdownSeconds--;
        if (this.countdownSeconds === 0) {
          this.stopCountdown();
        }
      }, 1000);
    },
    //停止倒计时
    stopCountdown() {
      clearInterval(this.countdownTimer);
      this.countdownSeconds = 60;
      this.isCountdownActive = false;
    },
    //拿到區號ID
    handleAreaCodeIDUpdate(newValue) {
      this.areaCodeID = newValue;
      console.log('areaCodeID:', this.areaCodeID);
    },

    //点击修改
    edit(){
      this.visible = true
    },
    changePhone(){
      const formData = new FormData();
      formData.append('area_code_id', this.areaCodeID);
      formData.append('phone', this.tel);
      formData.append('code', this.code);
      //提交數據
      changePhone(formData).then((res)=>{
        if(res.data.code===200){
          this.tel = "";
          this.selectedId = 1;
          this.isPopupVisible = true;
          this.popupText = '修改成功！';
          this.getMemberInfo();
          setTimeout(() => {
            this.isPopupVisible = false;
            this.visible = false;
          }, 2000);
        }else {
          this.isPopupVisible = true;
          this.popupText = res.data.message;
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }
      }).catch(err=> {
        console.log(err)
      })
    },
    //关闭弹窗
    closeDialog() {
      this.visible = false
    },
    confirmDialog() {
      this.visible = false
    },
    //上傳頭像
    handleImageChange(event) {
      this.apiShow = true
      this.imageFile = event.target.files[0];
      this.uploadAvatar();
    },
    uploadAvatar() {
      const formData = new FormData();
      formData.append('file', this.imageFile);
      UploadAvatar(formData).then((res)=>{
        if(res.data.code===200){
          this.avatar = res.data.data;
          this.getMemberInfo();
          this.apiShow = false;
        }
      }).catch(err=> {
        console.log(err)
      })
    },
    //复制
    copyOrderId1() {
      const url = `https://www.charmingspring.com/register/${this.user_info.invitation_code}`
      navigator.clipboard.writeText(url)
          .then(() => {
            this.isPopupVisible = true;
            this.popupText = '複製成功！';
            setTimeout(() => {
              this.isPopupVisible = false;
            }, 2000);
          })
          .catch(err => {
            console.log(err)
            this.isPopupVisible = true;
            this.popupText = '複製失敗！';
            setTimeout(() => {
              this.isPopupVisible = false;
            }, 2000);
          })
    }
  }
}
</script>

<style scoped>
.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.dialog-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 500px;

}
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.dialog-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.dialog-close {
  cursor: pointer;
}
.dialog-content {
  margin-bottom: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.upload-img{
  position: absolute;
  right: 0;
  bottom:0;
  cursor: pointer;
  opacity: 0;
}
.change{
  cursor: pointer;
}
</style>